(function () {
    'use strict';

    angular.module('common')
        .service('PageService', PageService);

    PageService.$inject = ['$rootScope', '$state', 'routesConstant'];

    function PageService($rootScope, $state, routesConstant) {

        var AFLPAGEVIEW = {
            LOGIN: "login",
            ADMIN: "admin",
            MFG: "mfg",
            LINK: "link",
            PROJECTS: "projects",
            ORGANIZATION: "organization",
            ORGANIZATION_TOOLS: "organization-tools",
            ORGANIZATION_IMPORT: "organization-import",
            ORGANIZATION_CONTACTS: "organization-contacts",
            FIBERGROUPS: "fibergroups",
            FIBERGROUPS_SUB: "fibergroups-sub",
            FIBERGROUPS_TEAM: "fibergroups-team",
            FIBERGROUPS_TRACE: "fibergroups-trace"
        };

        var aflPageViewsByState = {};

        (function init() {
            setCurrentPage({
                title: undefined,
                type: undefined,
                instructions: "(To Be Added)",
                actions: []
            });
        })();

        function setCurrentPage(page) {
            $rootScope.page = page;
        }

        function addAflPageViewToStateMap(route, aflPageView) {
            return aflPageViewsByState[route] = aflPageView;
        }

        /**
         * TODO to consider combining "aflPageView" with "page" as they do the same
         * @param aflPageView
         * @param skipResizeEvent
         */
        function setAflPageView(aflPageView, skipResizeEvent) {
            if (!skipResizeEvent) {
                triggerResize(); // no problem with order, as this is asynchronous
            }

            document.body.setAttribute('data-afl-page-view', aflPageView);
            return window.aflPageView = aflPageView;
        }

        function clearAflPageView() {
            setAflPageView('');
        }

        /**
         * set afl page view by state
         * @param skipResizeEvent
         */
        function setStateAflPageView(skipResizeEvent) {
            var pages = Object.keys(aflPageViewsByState)
                .filter(function (item) {
                    return checkIfAncestor($state.$current, item);
                })
                .sort()
                .reverse();

            if (pages && pages.length > 0) {
                setAflPageView(aflPageViewsByState[pages[0]], skipResizeEvent);
            }
        }

        function checkIfAncestor(currentState, ancestorStateName) {
            return currentState.includes[ancestorStateName] === true;
        }

        function getAflPageView() {
            return window.aflPageView;
        }

        function triggerResize() {
            $(window).trigger('resize');
        }

        function getCurrentPage() {
            return $rootScope.page;
        }

        function setTitle(title) {
            $rootScope.page.title = title;
        }

        function setDefaultTitle() {
            $rootScope.page.title = this.pages[$state.current.name] ? this.pages[$state.current.name].title : '';
        }

        function getTitle() {
            return $rootScope.page.title;
        }

        function addPages(pages) {
            angular.extend(this.pages, pages);
        }

        function getPages() {
            return this.pages;
        }

        angular.extend(this, {
            pages: {},
            AFLPAGEVIEW: AFLPAGEVIEW,
            setCurrentPage: setCurrentPage,
            getCurrentPage: getCurrentPage,
            addPages: addPages,
            getPages: getPages,
            setTitle: setTitle,
            setDefaultTitle: setDefaultTitle,
            getTitle: getTitle,
            setAflPageView: setAflPageView,
            clearAflPageView: clearAflPageView,
            getAflPageView: getAflPageView,
            setStateAflPageView: setStateAflPageView,
            triggerResize: triggerResize,
            addAflPageViewToStateMap: addAflPageViewToStateMap
        })

    }
})();
